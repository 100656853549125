import React from "react";
import {Col, Modal, Row} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import {observer} from "mobx-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCircleExclamation} from "@fortawesome/free-solid-svg-icons";


const StatusModal = observer((props: any) => {

    // const [state] = useState(() => new State());
    // const [showEulaAlert, setShowEulaAlert] = useState(false);

    if (props.resp?.ok) {
        console.log("Perfect! ");
    } else if (props.resp?.status === 400) {
        console.log("Oops! ");
    } else if (props.resp?.status === 0) {
        //props.show = false;
        console.log("res.status " + props?.resp?.status);
    } else {
        console.log("res else! ");
    }

    return (
        <Modal show={props.show}
               onHide={props.onHide}
               size="lg"
               dismissible
               aria-labelledby="contained-modal-title-vcenter"
               centered>
            <Modal.Header closeButton>
                <Modal.Title>Статус заявки</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {
                    // .ok
                    (props?.resp?.ok) ?
                        <Row>
                            <Col md={2}>
                                <FontAwesomeIcon color="green" size="xl" icon={faCheck}/>
                            </Col>
                            <Col md={10}>
                                <p>Заявка успешно отправллена! Ожидайте звонка на указанный номер.</p>
                            </Col>
                        </Row>
                        :
                        <Row>
                            <Col md={2}>
                                <FontAwesomeIcon color="red" size="xl" icon={faCircleExclamation}/>
                            </Col>
                            <Col md={10}>
                                <p>Ошибка на стороне сервера,заказ не создался! надо будет починить!</p>
                            </Col>
                        </Row>


                }
            </Modal.Body>
        </Modal>
    );
});
export default StatusModal;