import React from "react";
import {faEnvelope, faMapMarked, faPhone} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTelegram, faVk} from "@fortawesome/free-brands-svg-icons";

class Footer extends React.Component {

    render() {
        return <footer className="footer-container">
            <div className="container">
                <div className="row">
                    <div className="col-xs-2 col-sm-2 col-md-1 footer-logotype-container">
                        <div className="footer-logotype"/>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-5">
                        <div className="footer-short-info">
                            <span className="name" itemProp="name">Антикафе Зеленая Дверь</span>
                            <br/>
                            <time className="footer-time" itemProp="openingHours" dateTime="Mo-Su">
                                Мы открыты круглосуточно
                            </time>
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-4 col-md-3">
                        <a target="_blank" className="footer-contacts-item footer-email" href="https://vk.com/zdver" rel="noreferrer">
                            <FontAwesomeIcon icon={faVk} className="mr-2"/>
                            <span>Наш ВК</span>
                        </a>

                        <a target="_blank" className="footer-contacts-item footer-email" href="https://t.me/ZelDver" rel="noreferrer">
                            <FontAwesomeIcon icon={faTelegram} className="mr-2"/>
                            <span>@ZelDver</span>
                        </a>


                        <a target="_blank" className="footer-contacts-item"  itemProp="telephone" href="tel://+74955328758" rel="noreferrer">
                            <FontAwesomeIcon icon={faPhone} className="mr-2"/>
                            <span>+7 (495) 532-8758</span>
                        </a>

                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-3">
                        <a className="footer-contacts-item"
                           itemProp="address"
                           itemScope itemType="https://schema.org/PostalAddress"
                           href="https://maps.yandex.ru/?text=Москва, Милютинский переулок, 19/4 стр. 1&amp;sll=37.632197%2C55.765564&amp;ll=37.632197%2C55.765564&amp;spn=0.042014%2C0.013477&amp;z=15&amp;l=map"
                           target="_blank"
                           rel="noreferrer">
                            <FontAwesomeIcon icon={faMapMarked}/>
                            <span itemProp="streetAddress">&nbsp;Москва, Милютинский переулок, 19/4 стр. 1</span>,
                            <span itemProp="addressLocality"> Москва</span>
                        </a>

                        <a target="_blank" className="footer-contacts-item footer-email" href="mailto:info@zdver.com"
                           itemProp="email" rel="noreferrer">
                            <FontAwesomeIcon icon={faEnvelope}/>
                            <span> info@zdver.com</span>
                        </a>
                    </div>


                </div>
            </div>
        </footer>;
    }
}

export default Footer;
