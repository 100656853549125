import React from "react";

import {register} from 'swiper/element/bundle';


import {Swiper, SwiperSlide} from 'swiper/react';

import '../../css/ext/swiper/index.scss';
import {Navigation, Pagination} from "swiper/modules";

register();

function MainGoodies(props: any) {

    return (
        <div className="landingBox">
            <h2>Кофе и какао</h2>
            <h5>У нас итальянская кофемашина, а кофейные зёрна обжаривают персонально для нас.</h5>
            <br/>
            <h2>~40 сортов чая</h2>
            <h5>Качественный рассыпной и пакетированный</h5>
            <br/>
            <h2>Вкусняшки</h2>
            <h5>печенье, конфеты и попкорн на любой вкус, цвет и гендерную принадлженость</h5>

            <Swiper
                //@ts-ignore
                pagination={true}
                navigation={true}
                centeredSlides={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
            >
                {/* <img src="/images/index/goodies1.jpg"  height="200px" alt="hall"/>*/}
                <SwiperSlide className="text-center">
                    <div className="divImg"
                         style={{backgroundImage: "url('/images/index/3_1.jpg')"}}>
                    </div>
                </SwiperSlide>

                <SwiperSlide className="text-center">
                    <div className="divImg"
                         style={{backgroundImage: "url('/images/index/3_2.jpg')"}}>
                    </div>
                </SwiperSlide>

                <SwiperSlide className="text-center">
                    <div className="divImg"
                         style={{backgroundImage: "url('/images/index/3_3.jpg')"}}>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="text-center">
                    <div className="divImg"
                         style={{backgroundImage: "url('/images/index/3_4.jpg')"}}>
                    </div>
                </SwiperSlide>


            </Swiper>


            <br/>
            <br/>
        </div>
    );

}

export default MainGoodies