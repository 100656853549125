import React from 'react';
import ReactDOM from 'react-dom/client';

import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import About from "./pages/About/About";
import Feed from "./pages/Feed/Feed";
import Contacts from "./pages/Contacts/Contacts";
import NotFound from "./layout/NotFound";
import Rent from "./pages/Rent/Rent";
import Rules from "./pages/Rules/Rules";
import Shop from "./Shop/Shop";

import './css/index.scss'
import ShopView from "./Shop/view/ShopView";
import FeedView from "./pages/Feed/FeedView";
import Main from "./pages/Main/Main";
//import Main from "./pages/Main/Main";

const App: React.FC = () => (
    <BrowserRouter>
        <Routes>
            <Route path="about" element={<About/>}/>
            <Route path="/games" element={<Shop/>}/>
            <Route path="/games/:id" element={<ShopView/>}/>
            {/* <Route path="/" element={<Main/>}/>*/}
            <Route path="/" element={<Main/>}/>
            <Route path="events" element={<Feed/>}/>
            <Route path="/events/:name" element={<FeedView/>}/>
            <Route path="/halls" element={<Rent/>}/>
            <Route path="/rules" element={<Rules/>}/>
            <Route path="/contacts" element={<Contacts/>}/>
            <Route path="*" element={<NotFound/>}/>
        </Routes>
    </BrowserRouter>
)

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    //  <React.StrictMode>
    <App/>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
