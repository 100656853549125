import React from "react";

import {register} from 'swiper/element/bundle';


import {Swiper, SwiperSlide} from 'swiper/react';
import '../../css/ext/swiper/index.scss';
import {Navigation, Pagination} from "swiper/modules";


register();

function SecondPhotoSlider(props: any) {

    return (
        <div className="landingBoxWhite">
            <Swiper
                //@ts-ignore
                pagination={true}
                navigation={true}
                centeredSlides={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
            >
                {/* <img src="/images/index/goodies1.jpg"  height="200px" alt="hall"/>*/}
                <SwiperSlide className="text-center">
                    <div className="divImg"
                         style={{
                             minHeight: 300,
                             backgroundImage: "url('/images/index/2_1.jpg')"
                    }}>
                    </div>
                </SwiperSlide>

                <SwiperSlide className="text-center">
                    <div className="divImg"
                         style={{minHeight: 300, backgroundImage: "url('/images/index/2_2.jpg')"}}>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="text-center">
                    <div className="divImg"
                         style={{minHeight: 300, backgroundImage: "url('/images/index/2_3.jpg')"}}>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="text-center">
                    <div className="divImg"
                         style={{minHeight: 300, backgroundImage: "url('/images/index/2_4.jpg')"}}>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="text-center">
                    <div className="divImg"
                         style={{minHeight: 300, backgroundImage: "url('/images/index/2_5.jpg')"}}>
                    </div>
                </SwiperSlide>

            </Swiper>
        </div>
    );

}

export default SecondPhotoSlider