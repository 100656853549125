import React, {useState} from "react";
import {Alert, Col, Container, Modal, Row} from "react-bootstrap";
import {Product} from "../../models/product";
import DatePicker, {registerLocale} from "react-datepicker";
import {format} from 'date-fns'
import "react-datepicker/dist/react-datepicker.css";
import ModalGameCard from "./ModalGameCard";
import PhoneInput from "react-phone-number-input";
import {getPeriodDays, getPeriodName, getPeriodValue} from "../../models/rent_period";
import {RxInput} from "../components/mobx/RxInput";
import {observer} from "mobx-react";
import {WithFormField} from "../components/mobx/form";


//Calendar picker l10n
import ru from 'date-fns/locale/ru';
import {API_SHOP_CHECKOUT} from "../../config/urls";
import PriceSelector2 from "../components/PriceSelector2";

registerLocale('ru', ru)

class State {
    form = {
        name: new WithFormField<string>(""),
        phone: new WithFormField<string>(""),
        startDate: new WithFormField<Date>(new Date()),
        endDate: new WithFormField<Date>(new Date()),
        descr: new WithFormField<string>(""),
        table_game_id: 0,
        period: new WithFormField<number>(0),
        eula: false,
        telegram: false,
        // gender: new WithFormField("male")
    };
    /*constructor(props: any) {
        console.log()
        this.form.period.value = props.period ?? 0
    }*/

}


const CheckoutModal = observer((props: any) => {
    let model: Product = props.model;
    const [state] = useState(() => new State());
    const [showEulaAlert, setShowEulaAlert] = useState(false);

    // moment.locale('ru');
    // moment.tz.setDefault("Europe/Moscow");

    state.form.table_game_id = model.id ?? 0;
    //state.form.period.value = props.period ?? 0;

    //const minTime = new Date();
    //minTime.setHours(10, 0);

    // const maxTime = new Date();
    // maxTime.setHours(21, 0);

    function addDays(date: Date, days: number) {
        let result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    function calcMaxDate() {
        let _MaxDate = new Date();
        return addDays(_MaxDate, 30);
        // return _MaxDate;
    }

    function calcBackDate() {
        //state.form.startDate.value;
        //let backDate = state.form.startDate.value;
        //backDate.setDate(backDate.getDate() + getPeriodDays(state.form.period));

        //state.form.endDate.value = addDays(state.form.startDate.value, getPeriodDays(state.form.period) ?? 0);
        state.form.endDate.value.setDate(state.form.startDate.value.getDate() + getPeriodDays(state.form.period.value) ?? 0)
        return state.form.endDate.value;
    }

    function handleSubmit(event: any) {
        //  <pre>{JSON.stringify(state.form, null, 2)}</pre>
        //
        if (state.form.eula) {
            let data = new FormData();
            data.append('days', state.form.period.value.toString())
            data.append('descr', state.form.descr.value)
            // data.append('from', state.form.date.value.() )
            data.append('from', format(state.form.startDate.value, 'Y-MM-dd HH:mm:ss'))
            data.append('name', state.form.name.value)
            data.append('phone', state.form.phone.value)
            data.append('table_game_id', state.form.table_game_id.toString())
            // alert('A name was submitted: ' + JSON.stringify(state.form, null, 2));

            fetch(API_SHOP_CHECKOUT, {
                mode: 'no-cors',
                method: "POST",
                body: data,
            },).then(function (res) {

                console.log(res);
                if (res.ok) {
                    console.log("Perfect! ");
                } else if (res.status === 400) {
                    console.log("Oops! ");
                } else if (res.status === 0) {
                    //props.show = false;
                    console.log("res.status " + res.status);
                } else {
                    console.log("res else! ");
                }
                props.onSubmit(res);
            }).catch((error) => {
                console.log('error: ' + error);

            });
        } else {
            //console.log("showEulaAlert alert eula")
            setShowEulaAlert(true);
        }

        event.preventDefault();
    }


    return (
        <Modal show={props.show}
               onHide={props.onHide}
               size="lg"
               dismissible
               aria-labelledby="contained-modal-title-vcenter"
               centered>
            <form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Аренда игры</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <div className="part-1 alert  alert-success">
                                <ModalGameCard product={model}/>
                            </div>
                            <div className="alert alert-success" role="alert">
                                {/*   <h4 className="alert-heading">Витя ЗД, [27 Nov 2022, 17:03:58]:</h4>*/}
                                <p>Залог оплачивается в полном объеме наличными при получении игры
                                    При возврате игры в исходном состоянии, при учете естественного износа, мы
                                    возвращаем вам залог за вычетом стоимости аренды
                                </p>
                                <hr/>
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id="eula" name="eula"
                                           onChange={(value: any) => state.form.eula = value}/>
                                    <label className="form-check-label" htmlFor="eula">Согласен, куда я
                                        денусь</label>
                                </div>
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id="spam"/>
                                    <label className="form-check-label" htmlFor="spam">Подписаться на спам</label>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <Container>
                                <div className="text-center align-center justify-content-center">
                                    <div className="justify-content-center">

                                        <Row>
                                            <RxInput model={state.form.name}
                                                     attr={{
                                                         "className": "form-control",
                                                         "placeholder": "ФИО",
                                                         'required': 'true'
                                                     }}
                                                // className="form-control" id="name" placeholder="ФИО"
                                            />
                                        </Row>
                                        <br/>
                                        <Row>
                                            <PhoneInput
                                                className="w-100"
                                                placeholder="Телефон"
                                                defaultCountry="RU"
                                                onChange={(value: any) => state.form.phone.value = value}
                                                /* countrySelectProps={{ unicodeFlags: true }}*/
                                            />
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="telegram"
                                                       name="telegram"
                                                       onChange={(value: any) => state.form.telegram = value}/>
                                                <label className="form-check-label" htmlFor="telegram">Связаться через
                                                    Telegram</label>
                                            </div>
                                        </Row>
                                        <br/>
                                        <Row>
                                            <DatePicker
                                                //utcOffset={0}
                                                locale="ru"
                                                placeholderText="Дата начала аренды"
                                                className="form-control"
                                                required={true}
                                                dateFormat="d MMMM yyyy"  //dateFormat="d MMMM yyyy HH:mm"
                                                minDate={new Date()}
                                                maxDate={calcMaxDate()}
                                                //value={format(state.form.startDate.value, 'dd.MM.yyyy', {locale: ru})}
                                                //showTimeSelect
                                                //minTime={minTime}
                                                //maxTime={maxTime}
                                                selected={state.form.startDate.value}
                                                onSelect={
                                                    (date: Date) => {
                                                        state.form.startDate.value = date;

                                                        if ((date.getDay() === 6) || (date.getDay() === 0)) {
                                                            state.form.period.value = 3;
                                                        } else {
                                                            state.form.period.value = 0;
                                                        }
                                                        //console.log(date);
                                                        console.log("startDate:" + state.form.startDate.value)
                                                    }
                                                }
                                                onChange={
                                                    (date: Date) => {
                                                        //state.form.startDate.value = date;
                                                        //console.log(date);
                                                        //console.log(state.form.date.value)
                                                    }
                                                }
                                            />
                                            <br/>
                                            <PriceSelector2 selected={state.form.period}
                                                            startDate={state.form.startDate.value}
                                                            product={model}
                                                            onSelect={(period: number) => {
                                                                console.log(period);
                                                                state.form.period.value = period;
                                                                calcBackDate();
                                                                console.log("Set period:" + period);
                                                            }}/>

                                        </Row>
                                        <br/>
                                    </div>
                                </div>
                            </Container>
                            <div className="alert alert-success" role="alert">
                                <Row>Выбранный период: {getPeriodName(state.form.period.value)} </Row>
                                <Row>Дата начала: {format(state.form.startDate.value, 'dd.MM.yyyy')}</Row>
                                <Row>Дата возврата: {format(calcBackDate(), 'dd.MM.yyyy')} до 13.00
                                    <span className="text-danger"><sup> *</sup></span>
                                </Row>
                                <hr/>
                                <Row><p
                                    className="price">Стоимость: <span>{getPeriodValue(state.form.period.value, model)} ₽</span>
                                </p></Row>
                                <Row>Залог: {model.price_full}₽</Row>
                            </div>

                            <Container>
                                <Row>

                                    <RxInput model={state.form.descr}
                                             attr={
                                                 {
                                                     "className": "form-control",
                                                     "placeholder": "Комментарий",
                                                 }
                                             }
                                        // className="form-control" id="name" placeholder="ФИО"
                                    />
                                </Row>
                            </Container>
                            <p>
                                <span className="text-danger"><sup>*</sup></span> Если вы вернете позже этого времени,
                                то
                                аренда будет автоматически продлена на следующий промежуток времени
                            </p>
                        </Col>

                    </Row>

                    {/* <h4> {model.name}</h4>*/}
                    <br/>
                    <br/>
                    {/*    <p>
                    {model.price_day} руб
                </p>*/
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Alert show={showEulaAlert} variant="danger">
                        {/*<Alert.Heading></Alert.Heading>*/}
                        <p>Перед отправкой придется принять условия!</p>

                    </Alert>
                    {/*   <Button onClick={props.onHide} className="btn-primary"> Отмена</Button>*/}
                    <button type="submit" value="Submit" className="add-to-cart btn btn-default">Создать заказ</button>
                </Modal.Footer>
            </form>
        </Modal>
    );
});
export default CheckoutModal;