import React from "react";
import {EventModel} from "../../models/event";
import {format} from "date-fns";
import {EVENT_IMG_URL} from "../../config/urls";


interface Props {
    model: EventModel,
    /*
    type: propTypes.shape({
       name: propTypes.string.isRequired
      }).isRequired
    */
}

class FeedDayEvent extends React.Component<Props> {

    render() {
        //console.log("render event");
        //console.log(this.props.model);
        this.props.model.date = new Date(this.props.model.start);
        //const time = formatTime(this.props.model.from_dt);
        const time = format(this.props.model.date, 'HH:mm')
        return (
            <a className="event-card-link" href={`/events/${this.props.model.url}?start=${this.props.model.start}`}>
                {/*  href={`/events/${this.props.model.url}`}>*/}
                <article className="event-card-container"
                         style={{backgroundImage: `url(${EVENT_IMG_URL}/${this.props.model.image_name})`}}>
                    <footer className="event-card-footer">
                        <div className="event-card-meta">
                            <time dateTime={this.props.model.date.toISOString()}>
                                {this.props.model?.eventType?.name} в {time}
                            </time>
                        </div>
                        <h3 className="event-card-header">{this.props.model.name}</h3>
                    </footer>
                </article>
            </a>
        );
    }
}

export default FeedDayEvent;