import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRuble, faUsers} from "@fortawesome/free-solid-svg-icons";

interface Props {
    name: string,
    price: number,
    images: []
    capacity: number
}

class Room extends React.Component<Props> {

    renderSlider() {
        const slides = this.props.images.map((image, i) => (
            <img className="rent-slide" src={`/images/${image}.jpg`} key={i}/>
        ));

        return (
            <Slider speed={500} slidesToShow={1} slidesToScroll={1} dots={false} infinite>
                {slides}
            </Slider>
        );
    }


    render() {
        return (
            <div className="rent-room">
                {this.renderSlider()}
                <div className="rent-room-description">
                    <h4 className="rent-room-capacity">
                        <span className="fa fa-male fa-fixed-width"/>
                        <span><FontAwesomeIcon icon={faUsers}/> {this.props.capacity}  </span>
                    </h4>
                    <h2 className="rent-room-info" style={{backgroundColor: "#0000008f"}}>
                        <span className="name">{this.props.name}, </span>
                        {
                            (this.props.price > 0)
                                ?
                                <>
                             <span className="price">
                                 <span className="price-value">{this.props.price}</span>
                                 <FontAwesomeIcon icon={faRuble}/>/час
                            </span>
                                </>
                                : <>не <b className="text-success">ЗД</b>ается</>
                        }

                        <span className="fa fa-bookmark-o rent-room-bookmark"/>
                    </h2>
                </div>
            </div>
        );
    }
}


export default Room;
