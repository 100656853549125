import React from "react";
import {Product} from "../../models/product";
import {getPeriodName, getPeriodValue, rentPeriod} from "../../models/rent_period";


interface Props {
    product: Product | null,
    onSelect: Function
}

interface State {
    finalPrice: number
}

class PriceSelector extends React.Component<Props, State> {
    private readonly dayPriceRef: any;

    constructor(props: Props) {
        super(props)
        this.dayPriceRef = React.createRef<HTMLInputElement>();

        // @ts-ignore
        this.state = {finalPrice: props.product.periods['day_50']}
    }

    componentDidMount() {
        this.dayPriceRef.current.focus();
    }

    calcPrice = (period: number) => {
        this.props.onSelect(period);
        this.setState({
            finalPrice: getPeriodValue(period, this.props.product)
        })
    }

    /*    round50(price: number) {
            if (price < 50) {
                this?.dayPriceRef?.current?.focus(); // фокусим суточную кнопку
                return 50; // Возвращяем 50
            }
            return Math.ceil(price / 50) * 50;
        }*/

    render() {
        return (
            <>
                <div className="media-change">
                    <div className="btn-group btn-group-lg btn-vertical">
                        {/* <div className="btn-group mr-2" role="group" aria-label="Price group">*/}
                        <button ref={this.dayPriceRef} type="button" className="btn btn-info"
                                onClick={() => this.calcPrice(rentPeriod.day)}>{getPeriodName(rentPeriod.day)}
                        </button>
                        <button type="button" className="btn btn-info"
                                onClick={() => this.calcPrice(rentPeriod.twoDay)}>{getPeriodName(rentPeriod.twoDay)}
                        </button>
                        <button type="button" className="btn btn-info"
                                onClick={() => this.calcPrice(rentPeriod.week)}>{getPeriodName(rentPeriod.week)}
                        </button>
                        <button type="button" className="btn btn-info"
                                onClick={() => this.calcPrice(rentPeriod.weekend)}>{getPeriodName(rentPeriod.weekend)}
                        </button>
                    </div>
                </div>
                <br/>
                <h4 className="price">Стоимость: <span>{this.state.finalPrice}₽</span></h4>
            </>
        )
    }
}

export default PriceSelector;