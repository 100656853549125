module.exports = {
    library: {
        name: 'Библиотека',
        capacity: 30,
        weekdays: {
            morning: 3000,
            evening: 3500,
            night: 2500
        },
        weekend: {
            morning: 3500,
            evening: 4000,
            night: "Нет"
        },
        images: [
            'library-1',
            'library-2'
        ]
    },
    coach_hall: {
        name: 'Пуфиковый зал ',
        capacity: 20,
        weekdays: {
            morning: 2000,
            evening: 2500,
            night: 2000
        },
        weekend: {
            morning: 2500,
            evening: 3000,
            night: 2000
        },
        images: [
            'coach-hall-1',
            'coach-hall-2'
        ]
    },
    /*light_hall: {
        name: 'Большой зал',
        capacity: 50,
        weekdays: {
            /!* morning: 2250,
             evening: 4500,
             night: 2250*!/
        },
        weekend: {
            /!*  morning: 3150,
              evening: 5400,
              night: 4500*!/
        },
        images: [
            'light-hall-1',
            'light-hall-2'
        ]
    },
    concert_hall: {
        name: 'Кухня',
        capacity: 12,
        weekdays: {
            /!*morning: 2000,
            evening: 4000,
            night: 2000*!/
        },
        weekend: {
            /!*  morning: 2800,
              evening: 4800,
              night: 4500*!/
        },
        images: [
            'concert-hall-1',
            'concert-hall-2'
        ]
    }*/
};
