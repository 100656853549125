import React from "react";


class Spinner extends React.Component {
    render() {
        return (
            <div className="d-flex justify-content-center w-100">
                <div className="spinner-container">
                    <span className="sr-only">Загрузка...</span>
                    <div className="spinner-loader"/>
                </div>
            </div>
        );
    }
}

export default Spinner;
