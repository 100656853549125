import React from "react";
import {observer} from "mobx-react";
import GenreFilter from "./Filters/GenreFilter";
import TypeFilter from "./Filters/TypeFilter";
import CoopFilter from "./Filters/CoopFilter";
import ComplexFilter from "./Filters/ComplexFilter";
import DurationFilter from "./Filters/DurationFilter";
import RentStatusFilter from "./Filters/RentStatusFilter";


const ShopSidebar = observer(() => {

    return (
        <>
            {/* <PriceFilter/>*/}
            <RentStatusFilter/>
            <GenreFilter/>
            <TypeFilter/>
            <CoopFilter/>
            <ComplexFilter/>
            <DurationFilter/>
            <br/>


            {/*<button onClick={() => filterStore.clearFilter()}>Очистить</button>*/}

            {/*  <MultiSelectFilter data={this.getComplexityData()} placeholder="Сложность"/>

            <MultiSelectFilter data={this.getDurationData()} placeholder="Длительность"/>*/}
            <br/>
        </>
    )

});

export default ShopSidebar;