import React from "react";

import {register} from 'swiper/element/bundle';


import {Swiper, SwiperSlide} from 'swiper/react';
import '../../css/ext/swiper/index.scss';
import {Navigation, Pagination} from "swiper/modules";


register();
class MainCoworking extends React.Component {

    render() {
        return (
            <div className="landingBoxWhite">
                <h2>Коворкинг</h2>
                <h5>
                    Хотите поработать во дворе? На пуфике? В библиотеке - у нас всё есть! + бесплатный кофе! :)
                </h5>

                <Swiper
                    //@ts-ignore
                    pagination={true}
                    navigation={true}
                    centeredSlides={true}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                >
                    {/* <img src="/images/index/goodies1.jpg"  height="200px" alt="hall"/>*/}
                    <SwiperSlide className="text-center">
                        <div className="divImg"
                             style={{
                                 minHeight: 300,
                                 backgroundImage: "url('/images/index/4_1.jpg')"
                             }}>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide className="text-center">
                        <div className="divImg"
                             style={{minHeight: 300, backgroundImage: "url('/images/index/4_2.jpg')"}}>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="text-center">
                        <div className="divImg"
                             style={{minHeight: 300, backgroundImage: "url('/images/index/4_3.jpg')"}}>
                        </div>
                    </SwiperSlide>
                </Swiper>

              {/*  <Link to="/" className="btn btn-success">Подробнее</Link>*/}
            </div>
        );
    }
}

export default MainCoworking;