import {configure, makeAutoObservable, observable} from "mobx";

configure({
    enforceActions: "never",
})

export class WithFormField<T> {
    @observable value;

    constructor(initValue: T) {
        makeAutoObservable(this);
        this.value = initValue;
    }
}