import React from 'react';
import {Helmet} from "react-helmet";
import PricingSlide from "./PricingSlide";
import animatedScrollTo from "../../components/utils/animated-scroll-to";
import Navigation from "../../layout/Navigation";
import SlideNav from "./SlideNav";
import Ribbon from "../../components/Ribbon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown} from '@fortawesome/free-solid-svg-icons'
import Footer from "../../layout/Footer";


interface Props {
    //onCreate: (formState: State) => void;
}

interface State {
    activeSlide: number
    innerWidth: number
    innerHeight: number
}

//PureComponent
class About extends React.Component<Props, State> {
    slidesCount = 5;
    touchStart = 0;
    touchSensitivity = 5;
    scrollPending = false;
    slides = [];

    // this.scrollToSlide(0);

    constructor(props: any) {
        super(props);
        this.state = {activeSlide: 0, innerHeight: 0, innerWidth: 0};
    }

    componentDidMount() {
        //document.addEventListener('wheel', this.onScroll);
        document.addEventListener('touchstart', this.onTouchStart);
        document.addEventListener('touchend', this.onTouchEnd);
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.slides = [];

        for (let i = 0; i < this.slidesCount; i++) {
            // @ts-ignore
            this.slides.push(window.innerHeight * i);
        }
        //console.log(this.slides)

        //this.setState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight });
    }

    scrollToSlide = (slide: number) => {

        if (slide >= 0 && slide < this.slidesCount) {
            this.setState({activeSlide: slide});

            this.scrollPending = true;
            animatedScrollTo(this.slides[slide], 700, () => {
                this.scrollPending = false;
            });
        }
    };

    onArrowClick = () => {
        this.scrollToSlide(this.state.activeSlide + 1);
    };

    renderNavigation() {
        if (this.state.activeSlide === 0 || this.state.activeSlide === this.slidesCount - 1) {
            return <Navigation fixed/>;
        }
    }

    onTouchStart(e: any) {
        this.touchStart = e.touches[0].clientY;
    }

    onTouchEnd(e: any) {
        const touchEnd = e.changedTouches[0].clientY;

        if (this.touchStart > touchEnd + this.touchSensitivity) {
            this.scrollToSlide(this.state.activeSlide + 1);
        } else if (this.touchStart < touchEnd - this.touchSensitivity) {
            this.scrollToSlide(this.state.activeSlide - 1);
        }
    }

    /*
    onScroll = (e: WheelEvent) => {
        console.log(e);
        console.log(this);
        console.log(this.state);
        e.preventDefault();

        if (this.scrollPending) {
            return false;
        }

        const scrollDown = (e.deltaY || -e.deltaY || -e.detail) < 0;

        let activeSlide = this.state.activeSlide;

        if (scrollDown) {
            activeSlide++;
        } else {
            activeSlide--;
        }

        this.scrollToSlide(activeSlide);
    }
    */

    render() {
        return <>
            <Helmet>
                <title>О нас</title>
            </Helmet>
            <div className="about">
                {this.renderNavigation()}
                <SlideNav active={this.state.activeSlide} onClick={this.scrollToSlide}/>

                <section className="about-slide about-slide-top">
                    <div className="about-title">
                        {<Ribbon title="Зеленая Дверь" subTitle="Антикафе для друзей"/>}
                    </div>
                    <div className="about-arrow" onClick={this.onArrowClick}>
                        <FontAwesomeIcon icon={faAngleDown}/>
                    </div>
                </section>
                <section className="about-slide about-slide-yard"/>
                <section className="about-slide about-slide-location"/>
                <section className="about-slide about-slide-big"/>
                <PricingSlide/>
                <div className="about-footer">
                    <Footer/>
                </div>
            </div>
        </>
    }
}


export default About;
