import React, {useEffect, useState} from "react";

import {Swiper, SwiperSlide} from 'swiper/react';
import { GAMES_IMG_URL, URL_SHOP_GET_ALL} from "../../config/urls";
import {Link} from "react-router-dom";


const TableGamesMain = () => {
    const [games, setGames] = useState<any>([])
    useEffect(() => getData(0), [])

    function getData(_page: number) {
        //https://zdbot.zdver.com/api/table-games/get-all?&page=1&pageSize=16&is_active=1
        fetch(URL_SHOP_GET_ALL + "?page=1&pageSize=16&is_active=1")
            .then((response) => response.json())
            .then(data => {
                console.log("games", data["data"]);
                setGames(data["data"]);
            });
    }

    return (
        <div className="landingBoxWhite" style={{padding: 0}}>
            <p style={{padding: 10}}>
                <h2>Чем можно заняться</h2>
                <br/>
                <h5>Любишь настольные игры? Мафию? Приставки? - у нас есть всё!</h5>
                <br/>
                <h2>~300</h2>
                <h5>настольных игр</h5>
                <br/>
            </p>

            <Swiper
                //@ts-ignore
                slidesPerView={2}
                spaceBetween={10}
                pagination={true}
                navigation={true}
                //@ts-ignore
                // modules={[Pagination,Navigation]}
                className="mySwiper"
            >

                {
                    Object.entries(games).map(([key, value]:any) => (

                        <SwiperSlide key={key}>

                          {/*  <ShopListCard product={value}></ShopListCard>*/}

                            <a className="event-card-link" href={`/games/${value.id}`}>
                                {/*  href={`/events/${this.props.model.url}`}>*/}
                                <article className="event-card-container"
                                         style={{
                                             height: 250,
                                             backgroundImage: `url(${GAMES_IMG_URL}/400/${value.image_name})`
                                }}>
                                    <footer className="event-card-footer">
                                        <div className="event-card-meta">
                                            {/* <time dateTime={value.date.toISOString()}>
                                                {value?.eventType?.name} в
                                            </time>*/}
                                        </div>
                                        <h5 className="event-card-header" style={{fontSize: 10}}>{value?.name}</h5>
                                    </footer>
                                </article>
                            </a>



                        </SwiperSlide>
                    ))
                }


            </Swiper>
            <p className="text-center">
            <Link to="/games" className="btn  btn-lg btn-success mb-3">В каталог игр</Link>
            </p>
            {/*
            <swiper-container
                //ref={swiperElRef}
                slides-per-view="1"
                height="100"
                navigation="true"
                pagination="true"
            >
                <swiper-slide>
                    <div>
                        <img src="/images/coach-hall-1.jpg" height="400px" alt="hall"/>
                        <div className="landingBoxWhite" style={{position: "absolute", bottom: 10, left: 10}}>
                            <h2>Пуфики</h2> тут есть приставки
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide><img src="/images/library-1.jpg" height="400px" alt="hall"/></swiper-slide>
                <swiper-slide><img src="/images/coach-hall-1.jpg" height="400px" alt="hall"/></swiper-slide>

            </swiper-container>*/}
        </div>
    );

}

export default TableGamesMain;
//module.exports = Main;
