import Spinner from "../../components/Spinner";
import React, {useEffect, useState} from "react";
import {URL_SHOP_FILTER_THEME} from "../../config/urls";
import {filterStore} from "../store/FilterStore";
import MultiSelectFilter from "../components/filters/MultiSelectFilter";


const GenreFilter = () => {
    const [data, setData] = useState<any>();

    useEffect(() => {
        fetch(URL_SHOP_FILTER_THEME).then(response => response.json()).then(data => setData(data.data))
    }, [])

    function onSelect(ids: any[]) {
        filterStore.setGenre(ids ?? [])
    }

    return (
        <>
            {
                data != null
                    ? <MultiSelectFilter selectedIDs={filterStore.genreIds} data={data} placeholder="Жанры"
                                         onSelect={onSelect}/>
                    : <Spinner/>
            }
        </>


    )
}
export default GenreFilter;