import React from "react";
import Footer from "../../layout/Footer";
import Header from "../../layout/Header";
import {Helmet} from "react-helmet";
import RentPrices from "./RentPrices";


class Rent extends React.Component {
    render() {
        return (
            <div className="rent">
                <Helmet>
                    <title>Аренда залов</title>
                </Helmet>
                <Header background="/images/zdver-crowd2.png" content='Аренда залов'/>
                <div className="container">
                    <br/>
                    <div className="alert alert-secondary">
                        Вы можете в любое время находиться в любом из наших залов, если он кем-то не забронирован.
                        Бронь столов бесплатная, просто позвоните нам по телефону:
                        <a href="tel:+74955328758"> +7(495)532-87-58</a> или напишите в нашу <a
                        href="https://vk.com/zdver">группу в VK</a>
                    </div>
                    <RentPrices/>


                    <hr/>
                    <div>
                        <a href="https://zdver.com"><h5>Залы антикафе Wooden Door*</h5></a>
                        <span>*Антикафе Wooden Door расположено в 7 минутах ходьбы от нас рядом с метро Сухаревская.
                           Там есть своя кухня, пиво и кальяны, потому вход туда строго 18+. </span>
                    </div>
                    <br/>
                    <hr/>
                    {/*<h2 className="rent-heading">Бесплатно</h2>
                    <div className="row rent-features-list">
                        <div className="col-md-offset-1 col-md-2">
                            <img className="rent-feature" src="/images/video_projector.png" />
                            <span className="rent-feature-title">Проектор</span>
                        </div>
                        <div className="col-md-2">
                            <img className="rent-feature" src="/images/statistics.png" />
                            <span className="rent-feature-title">Экран</span>
                        </div>
                        <div className="col-md-2">
                            <img className="rent-feature" src="/images/audio_wave.png" />
                            <span className="rent-feature-title">Звук</span>
                        </div>
                        <div className="col-md-2">
                            <img className="rent-feature" src="/images/ratings.png" />
                            <span className="rent-feature-title">Флипчарт</span>
                        </div>
                        <div className="col-md-2">
                            <img className="rent-feature" src="/images/microphone.png" />
                            <span className="rent-feature-title">Микрофон</span>
                        </div>
                        <div className="rent-clearfix" />
                    </div>
                    <h2 className="rent-heading">За деньги</h2>
                    <div className="row rent-features-list">
                        <div className="col-md-offset-2 col-md-2">
                            <img className="rent-feature" src="/images/badge.png" />
                            <span className="rent-feature-title">Бэйджи</span>
                        </div>
                        <div className="col-md-2">
                            <img className="rent-feature" src="/images/coffee_maker.png" />
                            <span className="rent-feature-title">Кофебрейк</span>
                        </div>
                        <div className="col-md-2">
                            <img className="rent-feature" src="/images/restaurant.png" />
                            <span className="rent-feature-title">Обед</span>
                        </div>
                        <div className="col-md-2">
                            <img className="rent-feature" src="/images/pen.png" />
                            <span className="rent-feature-title">Канцелярские товары</span>
                        </div>
                        <div className="col-md-2">
                            <img className="rent-feature" src="/images/conference.png" />
                            <span className="rent-feature-title">Администрирование</span>
                        </div>
                        <div className="rent-clearfix" />
                    </div>*/}
                    {/* <div className="rent-contact">
                        <div className="col-xs-4">
                            <img className="rent-manager-img" src="/images/manager_photo.svg" />
                        </div>
                        <div className="col-xs-8">
                            <h4 className="rent-manager-desc">
                                Анна
                                <small className="rent-manager-desc-small">, менеджер по аренде</small>
                            </h4>
                            <div>
                                <span className="fa fa-phone fa-fixed-width" /> +7 (968) 463-7491
                            </div>
                            <div>
                                <a className="rent-manager-link" href="mailto:info@zdver.com">
                                    <span className="fa fa-envelope-o fa-fixed-width" />
                                    info@zdver.com
                                </a>
                            </div>
                            <div>
                                <span className="fa fa-facebook fa-fixed-width" />
                                <a className="rent-manager-link" href="https://facebook.com/zdver">
                                    Facebook
                                </a>
                            </div>
                            <div>
                                <span className="fa fa-vk fa-fixed-width" />
                                <a className="rent-manager-link" href="https://vk.com/zdver">
                                    ВКонтакте
                                </a>
                            </div>
                        </div>
                        <div className="rent-clearfix" />
                    </div>*/}
                </div>
                <Footer/>
            </div>
        );
    }
}

export default Rent;
