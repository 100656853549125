import {action, computed, makeAutoObservable, observable, reaction} from 'mobx'
import {productStore} from "./ProductStore";

export class FilterStore {
    constructor() {
        makeAutoObservable(this);
        //TODO NEED FIX IT
        //reaction(() => this.priceSelectedRange, () => productStore.clearItems())
        reaction(() => this.avail, () => productStore.clearItems())
        reaction(() => this.genreIds, () => productStore.clearItems())
        reaction(() => this.typeIds, () => productStore.clearItems())
        reaction(() => this.coopIds, () => productStore.clearItems())
        reaction(() => this.complexIds, () => productStore.clearItems())
        reaction(() => this.durationIds, () => productStore.clearItems())
    }

    //@observable priceSelectedRange: number[] = [];
    @observable avail: string = "all";
    //  @observable availSale: boolean = false;
    @observable genreIds: number[] = [];
    @observable typeIds: number[] = [];
    @observable coopIds: number[] = [];
    @observable complexIds: number[] = [];
    @observable durationIds: number[] = [];
    @observable searchQuery: string = "";

    //setPrice = (min: number, max: number) => this.priceSelectedRange = [min, max];
    setAvail = (value: any) => this.avail = value.target.value;
    //setAvailPlace = (value: boolean) => this.availPlace = value;
    setGenre = (ids: number[]) => this.genreIds = ids;
    setType = (ids: []) => this.typeIds = ids;
    setCoop = (ids: []) => this.coopIds = ids;
    setComplex = (ids: []) => this.complexIds = ids;
    setDuration = (ids: []) => this.durationIds = ids;
    setSearchQuery = (q: string) => this.searchQuery = q;

    /*  "&price_from=" + (this.priceSelectedRange[0] ?? '') +
      "&price_to=" + (this.priceSelectedRange[1] ?? '') +*/
    @computed
    get filterParams(): string {
        return ((this.avail === "rent") ? "&available_for_rent=1" : '') +
            ((this.avail === "place") ? "&is_activ=1" : '') +
            ((this.genreIds?.length > 0) ? "&themesIds=" + (this.genreIds?.join(",")) : "") +
            ((this.typeIds?.length > 0) ? "&typeIds=" + (this.typeIds?.join(",")) : "") +
            ((this.coopIds?.length > 0) ? "&coopIds=" + (this.coopIds?.join(",")) : "") +
            ((this.complexIds?.length > 0) ? "&complexityIds=" + (this.complexIds?.join(",")) : "") +
            ((this.durationIds?.length > 0) ? "&durationIds=" + (this.durationIds?.join(",")) : "") +
            ((this.searchQuery?.length > 1) ? "&multiName=" + (this.searchQuery) : "")
    }

    @action
    clearFilter() {
        //  this.priceSelectedRange = [];
    }
}

export const filterStore = new FilterStore()