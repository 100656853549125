import React from "react";

import {Helmet} from "react-helmet";
import Header from "../../layout/Header";
import {Card} from "react-bootstrap";
import MainHallSlider from "./HallSlider";
import MainPrice from "./Price";
import MainGoodies from "./Goodies";
import MainCoworking from "./Coworking";
import {Link} from "react-router-dom";
import AfishaPrice from "./Afisha";
import TableGamesMain from "./TableGames";
import Footer from "../../layout/Footer";
import FirstPhotoSlider from "./FirstPhotoSlider";
import SecondPhotoSlider from "./SecondPhotoSlider";


class Main extends React.Component {

    static defaultProps = {
        // showVideo: true,
    }

    constructor(props: any) {
        super(props);
        this.state = {showVideo: false};
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Главная</title>
                </Helmet>

                <Header background="/images/zdver-crowd2.png" big content=" "/>


                <div className="text-center h-25 p-1 mb-3 landingBoxWhite">
                    <img src="/logoName.svg" alt="Logo GreenDoor"/>
                </div>

                {
                    /*   <div className="container p-0 no-gutters">
                    <Row className="row-cols-lg-2 row-cols-sm-1 no-gutters">
                        <Col><MainHallSlider/></Col>
                        <Col><MainPrice/></Col>
                        <Col><MainGoodies/></Col>
                        <Col><MainCoworking/></Col>
                    </Row>
                </div>*/
                }

                <div className="container noMobileMargin">
                    <div className="p-0 no-gutters text-center justify-content-center">
                        <div className="card-columns justify-content-center text-left">
                            <Card className="p-2 landingBox">
                                <h2 className="p-1 text-left"
                                    style={{
                                        fontFamily: "Unbounded",
                                        fontSize: 30,
                                        fontWeight: "bold",
                                        lineHeight: "130%"
                                    }}>
                                    <b>Зеленая дверь</b> — уютный уголок старой Москвы в центре
                                    современного
                                    мегаполиса
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                </h2>
                                <p className="text-center pb-4">
                                    <Link to="/about" className="btn btn-lg btn-light text-center">Подробнее о
                                        нас</Link>
                                </p>

                            </Card>
                            <Card><FirstPhotoSlider/></Card>
                            <Card><MainPrice/></Card>
                            <Card><AfishaPrice/></Card>
                            <Card>
                                <TableGamesMain/>
                                <SecondPhotoSlider/>
                            </Card>
                            <Card><MainGoodies/></Card>
                            <Card><MainCoworking/></Card>
                            <Card><MainHallSlider/></Card>
                        </div>
                    </div>

                </div>

                <Footer/>

            </div>
        );
    }
}

export default Main;
//module.exports = Main;
