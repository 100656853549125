import Spinner from "../../components/Spinner";
import React, {useEffect, useState} from "react";
import {URL_SHOP_FILTER_TYPE} from "../../config/urls";
import {filterStore} from "../store/FilterStore";
import MultiSelectFilter from "../components/filters/MultiSelectFilter";


const TypeFilter = () => {
    const [data, setData] = useState<any>();

    useEffect(() => {
        fetch(URL_SHOP_FILTER_TYPE).then(response => response.json()).then(data => setData(data.data))
    }, [])

    function onSelect(ids: any) {
        filterStore.setType(ids)
        //console.log(ids);
    }

    return (
        <>
            {
                data != null
                    ? <MultiSelectFilter selectedIDs={filterStore.typeIds} data={data} placeholder="Типы"
                                         onSelect={onSelect}/>
                    : <Spinner/>
            }
        </>


    )
}
export default TypeFilter;