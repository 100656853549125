import React from "react";
import {Link} from "react-router-dom";


class MainPrice extends React.Component {

    static defaultProps = {
        // showVideo: true,
    }

    constructor(props: any) {
        super(props);
        this.state = {showVideo: false};
    }

    render() {
        return (
            <div className="landingBox">
                <h2>Стоимость</h2>
                <h5
                    style={{
                        fontFamily: "Unbounded",
                        fontSize: 22,
                        fontWeight: "regular",
                        lineHeight: "130%"
                    }}
                >Вы платите только за время, все услуги в антикафе бесплатны.</h5>
                <br/>
                <h5
                    style={{
                        fontFamily: "Unbounded",
                        fontSize: 22,
                        fontWeight: "regular",
                        lineHeight: "130%"
                    }}
                ><b>3₽ в минуту</b></h5>
                <br/>
                <h5
                    style={{
                        fontFamily: "Unbounded",
                        fontSize: 22,
                        fontWeight: "regular",
                        lineHeight: "130%"
                    }}
                >Студенческий «стопчек» — <b style={{fontFamily: "UnboundedBold"}}>490 руб</b>.</h5>
                <h5
                    style={{
                        fontFamily: "Unbounded",
                        fontSize: 22,
                        fontWeight: "regular",
                        lineHeight: "130%"
                    }}
                >Обычный «стопчек» — <b style={{fontFamily: "UnboundedBold"}}>690 руб</b>.</h5>
                <p className="text-center pt-4 pb-3">
                    <Link to="/about" className="btn btn-lg btn-light">Подробнее</Link>
                </p>
            </div>
        );
    }
}

export default MainPrice;
//module.exports = Main;
