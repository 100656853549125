import React from "react";
import classNames from "classnames";
import Room from "./Room";


const rooms = require('./rooms');

interface State {
    days: string,
    time: string,
}

class RentPrices extends React.Component<any, State> {

    constructor(props: any) {
        super(props);
        this.state = {days: 'weekdays', time: 'morning'};
    }

    renderRooms() {
        return Object.keys(rooms).map((room, i) =>
            <div className="col-md-6" key={i}>
                <Room {...rooms[room]} price={rooms[room][this.state.days][this.state.time]}/>
            </div>
        );
    }


    onWeekdaysClick = () => this.setState({days: 'weekdays'});

    onWeekendClick = () => this.setState({days: 'weekend'});

    onTimeSelect = (e: any) => this.setState({time: e.target.value});

    render() {
        const weekdaysClasses = classNames('rent-control-item', 'rent-control-day', {
            active: this.state.days === 'weekdays'
        });

        const weekendClasses = classNames('rent-control-item', 'rent-control-day', {
            active: this.state.days === 'weekend'
        });

        return (
            <div className="rent-prices">
                <div className="rent-controls">
                    <div className={weekdaysClasses} onClick={this.onWeekdaysClick}>
                        Понедельник - Пятница
                    </div>
                    <div className={weekendClasses} onClick={this.onWeekendClick}>
                        Вечер пятницы - Воскресенье
                    </div>
                    <select className="rent-control-item" onChange={this.onTimeSelect}>
                        <option value="morning">с 6.00 до 17.00</option>
                        <option value="evening">с 17.00 до 23.00</option>
                        <option value="night">с 23.00 до 6.00</option>
                    </select>
                </div>
                <div className="rent-rooms">
                    <div className="row">
                        {this.renderRooms()}
                    </div>
                </div>
            </div>
        );
    }
}

export default RentPrices;
