import {Product} from "./product";

// eslint-disable-next-line no-unused-vars
export enum rentPeriod {day, twoDay, week, weekend}

//const PERIOD = {DAY: 1, TWODAY: 1.5, WEEK: 2, WEEKEND: 1.7};

export function getPeriodName(id: number): string {
    switch (id) {
        case 0:
            return "Сутки"
        case 1:
            return "2 Суток"
        case 2:
            return "Неделя"
        case 3:
            return "Выходные"
        default:
            return "Сутки";
    }
}

export function getPeriodDays(id: number): number {
    switch (id) {
        case 0:
            return 1
        case 1:
            return 2;
        case 2:
            return 7;
        //todo need found weekend
        case 3:
            return 2;
        default:
            return 1;
    }
}

export function getPeriodValue(period: number, model: Product | null): number {
    switch (period) {
        case 1:
            // @ts-ignore
            return model.periods['twoday_50'];
        case 2:
            // @ts-ignore
            return model.periods['week_50'];
        case 3:
            // @ts-ignore
            return model.periods['weekend_50'];
        default:
            // @ts-ignore
            return model.periods['day_50'];
    }
}