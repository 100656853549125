import React, {useCallback} from "react";
import {observer} from "mobx-react";

interface RxInputProps {
    model: { value: string };
    //htmlAttrs?: React.HTMLAttributes<HTMLInputElement>;
    attr?: any;
}

export const RxInput = observer((props: RxInputProps) => {
    const model = props.model;

    const handleChange = useCallback((e: any) => {
        model.value = e.target.value;
        //console.log(model.value);
    }, [model]);

    return <input value={model.value} onChange={handleChange} {...props.attr} />;
});
