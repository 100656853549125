import React from "react";
import {GAMES_IMG_URL} from "../../config/urls";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPeopleGroup} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {Product} from "../../models/product";
import {Col, Row} from "react-bootstrap";


interface Props {
    product: Product,
}

class ShopListMiniCard extends React.Component<Props> {
    render() {
        return (
            <div className="col">
                <Link to={"/games/" + this.props.product.id}>
                    <div id={"product-" + this.props.product.id} className="single-product card">
                        <div className="rentStatus">
                            &nbsp;
                            {
                                (!this.props.product.available_for_rent && !this.props.product.available_for_sale) ?
                                    <button className="btn btn-warning">Только в заведении</button> :
                                    <button className="btn btn-success">В заведении</button>
                            }
                            &nbsp;
                            {
                                this.props.product.available_for_rent ?
                                    <button className="btn btn-success">Аренда</button> : ''
                            }
                            &nbsp;
                            {
                                this.props.product.available_for_sale ?
                                    <button className="btn btn-success">Покупка</button> : ''
                            }


                        </div>
                        <div className="part-1">
                            {this.props.product.image_name
                                && <div className="product-image"
                                        style={{backgroundImage: `url(${GAMES_IMG_URL}/400/${this.props.product.image_name})`}}/>
                            }
                            {!this.props.product.image_name && <div className="product-image"/>}
                            {/*  <ul>
                                    <li><a href="#"> <FontAwesomeIcon icon={faShoppingCart}/></a></li>
                                    <li><a href="#"> <FontAwesomeIcon icon={faHeart}/></a></li>
                                    <li><a href="#"> <FontAwesomeIcon icon={faExpand}/></a></li>
                                </ul>*/
                            }
                        </div>
                        <div className="part-2">
                            <h3 className="product-title">{this.props.product.name}</h3>
                            <p className="ellipse two-lines">{this.props.product.short_descr}</p>
                            <div>
                                <Row>
                                    <Col>
                                        <FontAwesomeIcon icon={faPeopleGroup}/>
                                        <span>&nbsp;{this.props.product.players_from} - {this.props.product.players_to}</span>
                                    </Col>
                                </Row>
                            </div>
                            {/*   <h4 className="product-old-price">{this.props.price}</h4>*/}
                        </div>
                    </div>
                </Link>
            </div>
        );
    }
}

export default ShopListMiniCard;