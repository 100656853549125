import React from "react";
import Header from "../layout/Header";
import ShopList from "./ShopList";
import Footer from "../layout/Footer";
import {Helmet} from "react-helmet";
import ShopSidebar from "./ShopSidebar";

class Shop extends React.Component<any, any> {
    //private todoStore: FilterStore = new FilterStore();

    constructor(props: any) {
        super(props);
        this.state = {};

    }

    render() {
        return <div className="h-100">
            <Helmet>
                <title>Настолки</title>
            </Helmet>
            <Header background="/images/mikl.night_racks_with_board_games.png"
                    content="Возьми в прокат - и покатай дома!"/>
            <div className="container-fluid">
                <div className="row">
                    <aside className="col-sm-12 col-md-3 col-lg-2 filer-sidebar pt-2">
                        <ShopSidebar/>
                    </aside>

                    <div className="col">
                        <ShopList/>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    }
}

export default Shop;