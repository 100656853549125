import React from "react";
import {filterStore} from "../store/FilterStore";
import MultiSelectFilter from "../components/filters/MultiSelectFilter";
import ComplexityData from '../../config/complexity.json';
import {defaultFilter} from "../../models/product";


const ComplexFilter = () => {
    function getComplexityData() {
        let complexFilter: defaultFilter[] = [];
        Object.keys(ComplexityData).map((key, i: any) =>
            // @ts-ignore
            complexFilter.push({name: ComplexityData[key], id: key})
        )
        return complexFilter;
    }

    function onSelect(ids: any) {
        filterStore.setComplex(ids)
    }

    return <MultiSelectFilter selectedIDs={filterStore.complexIds} data={getComplexityData()} placeholder="Сложность"
                              onSelect={onSelect}/>


}
export default ComplexFilter;