import React, {Component} from 'react';
import Select from 'react-select';
import * as Animated from 'react-select/animated';
import {defaultFilter} from "../../../models/product";
import {toJS} from "mobx";

interface State {
    selected: any[]
}

type Props = {
    onSelect: Function,
    data: defaultFilter[],
    placeholder: string,
    selectedIDs: number[]
};

class MultiSelectFilter extends Component<Props, State> {
    localData: any = [];
    selected = [];

    constructor(props: any) {
        super(props);
        this.localData = [];
        this.props.data.map((v) =>
            this.localData.push({
                //label: v.name + (v.count ? " (" + v.count + ")" : ""),
                label: v.name,
                value: v.id,
            })
        );
    }

    getLocalDataById = (id: number) => {
        // console.log("try getLocalDataById: "+id)
        //console.log( this.localData)
        return this.localData.find((element: any) => {
            return element.value === id;
        })
    }

    getSelected = () => {

        if (this.props.selectedIDs?.length > 0) {
            console.log("try getSelected, count: " + this.props.selectedIDs.length);
            //console.log(toJS(this.props.selectedIDs));
            let _selectedData: any[] = [];
            toJS(this.props.selectedIDs).map((id: number) => {
                    //console.log(this.getLocalDataById(id));
                    _selectedData.push(this.getLocalDataById(id));
                }
            )
            //console.log(_selectedData);
            return _selectedData;
        }

    }

    worker = () => {
        if (this.state.selected.length > 0) {
            const result = this.state.selected.map((a) => a.value);
            this.props.onSelect(result)
        } else {
            this.props.onSelect()
        }
    }

    render() {
        return (
            <>
                {this.props.data.length && (
                    <Select className="multi-select" closeMenuOnSelect={false} isMulti components={Animated}
                            onChange={async (val: any) => {
                                await this.setState({selected: val})
                                this.worker();
                            }
                            }
                            defaultValue={this.getSelected}
                        /*   onMenuClose={() => {}}*/
                            options={this.localData}
                            placeholder={this.props.placeholder}
                    />
                )}
            </>
        );
    }
}

export default MultiSelectFilter;