import React from "react";
import classNames from "classnames";

interface Props {
    active: boolean
    onClick: (...args: any[]) => any;
}


class ContactsVideo extends React.Component<Props> {
    static defaultProps = {
        active: false,
        onClick: {},
    }

    private _video: React.ReactInstance | any;
    private _intervalId: any;

    //private videoRef: React.RefObject<unknown>;
    //
    // constructor(props: any) {
    //     super(props)
    //     //this.videoRef = React.createRef()
    // }

    componentDidMount() {
        // eslint-disable-next-line react/no-string-refs
        this._video = this.refs.video;
        //console.log(this._video.type);
        this._video.volume = 0;
    }

    UNSAFE_componentWillReceiveProps({active}: any) {
        if (this._intervalId) {
            clearInterval(this._intervalId);
        }

        if (active && this._video.paused) {
            this._video.play();
        }

        const step = active ? 0.01 : -0.01;
        this._intervalId = setInterval(() => {
            const volume = (this._video.volume + step).toFixed(2);
            if (volume < 0 || volume > 0.5) {
                clearInterval(this._intervalId);
            } else {
                this._video.volume = volume;
            }
        }, 10);
    }

    render() {
        const className = classNames('contacts-video-container', {active: this.props.active});
        return (
            <div className={className}>
                {/* eslint-disable-next-line react/no-string-refs */}
                <video ref="video" className="contacts-video" src="/video/way-to-cafe.mp4"
                       poster="/images/way-to-cafe.png" onClick={this.props.onClick} autoPlay loop
                />
            </div>
        );
    }
}

export default ContactsVideo;
