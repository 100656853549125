import React from "react";

class PricingSlide extends React.Component {
    shouldComponentUpdate = () => false;

    render() {
        return (
            <section className="about-slide about-bottom-slide">
                <div className="about-price">
                    <div className="container">
                        <div className="col-sm-4">
                            <div className="about-pricing"/>
                        </div>

                        <p>3₽ в минуту</p>
                       {/* <p>Далее — по 2₽ в минуту</p>*/}
                        <p>Стопчек<sup>*</sup> 690₽</p>
                        <p>Стопчек<sup>*</sup> для студентов 490₽</p>
                        <br/>
                        <p><sup>*</sup>Сумма, больше которой вы не заплатите, находясь в заведении в течение одной
                            рабочей смены (рабочая смена начинается в 9.00 и заканчивается в 8.59 следующего дня)
                        </p>
                        <p>Стопчек оплачивается только наличными, при оплате картой расчёт ведётся по основному
                            тарифу</p>
                        <br/>
                        <br/>
                        <br/>
                    </div>
                    <br/>
                    <br/>

                </div>
                <br/>
                <br/>
            </section>

        );
    }
}

export default PricingSlide;
