export const URL_SHOP_GET_ALL = 'https://zdbot.zdver.com/api/table-games/get-all';
export const URL_SHOP_GET_BY_ID = 'https://zdbot.zdver.com/api/table-games/get';

export const URL_SHOP_FILTER_COOP = 'https://zdbot.zdver.com/api/filters/coop';
export const URL_SHOP_FILTER_PRICE = 'https://zdbot.zdver.com/api/filters/price';
export const URL_SHOP_FILTER_THEME = 'https://zdbot.zdver.com/api/filters/theme';
export const URL_SHOP_FILTER_TYPE = 'https://zdbot.zdver.com/api/filters/type';
export const API_SHOP_CHECKOUT = 'https://zdbot.zdver.com/api/table-games-in-use/create';

//https://zdbot.zdver.com/files/public/tg/400/
//export const API_EVENT_WEEK = 'https://api.zdver.com/api/events/get-for-week';
export const API_EVENT_WEEK = 'https://zdbot.zdver.com/api/events/get-for-week';
export const API_EVENT_BY_NAME = 'https://zdbot.zdver.com/api/events/get-by-url';

export const GAMES_IMG_URL = 'https://zdbot.zdver.com/files/table_games/';
export const EVENT_IMG_URL = 'https://zdbot.zdver.com/files/events/';
//export const URL_SHOP_FILTER_COMPLEX = 'https://api.zdver.com/api/filters/complexity';
//export const URL_SHOP_FILTER_DUR = 'https://api.zdver.com/api/filters/duration';
//export const API_EVENT_URL = 'https://zdver.com/api/events?page=0';
//export const API_EVENT_URL = 'https://api.zdver.com/api/events/get-all';
//export const API_EVENT_BY_NAME = 'https://api.zdver.com/api/events/get-all';


