import padNumber from "./pad-number"
import isSameDate from "./is-same-date";

const months = require('./months');

function formatDate(date: Date) {
    const today = new Date();
    if (isSameDate(today, date)) {
        return 'Сегодня';
    }

    const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
    if (isSameDate(tomorrow, date)) {
        return 'Завтра';
    }

    const day = padNumber(date.getDate());
    const month = months[date.getMonth()];
    return `${day} ${month}`;
}

export default formatDate;
