import React, {useEffect, useState} from "react";

import {Swiper, SwiperSlide} from 'swiper/react';
import {API_EVENT_WEEK, EVENT_IMG_URL} from "../../config/urls";
import {Link} from "react-router-dom";


const AfishaPrice = () => {
    const [events, setEvents] = useState<any>([])
    useEffect(() => getData(0), [])

    function getData(_page: number) {
        fetch(API_EVENT_WEEK + "?page=" + _page)
            .then((response) => response.json())
            .then(data => {
                //console.log(data)
                let keys = Object.keys(data["data"]);
                //console.log("keys", keys);
                let ThreeDays = [
                    ...data["data"][keys[0]],
                    ...data["data"][keys[1]],
                    ...data["data"][keys[2]]
                ];
                console.log("ThreeDays", ThreeDays);
                setEvents(ThreeDays);
            });
    }

    return (
        <div className="landingBoxWhite" style={{padding: 0}}>
            <p style={{padding: 10}}>
                <h2>Наша афиша</h2>
                <p>Ты можешь найти себе мероприятие по душе</p>
            </p>

            <Swiper
                //@ts-ignore
                pagination={true}
                navigation={true}
                //@ts-ignore
                // modules={[Pagination,Navigation]}
                className="mySwiper"
            >

                {
                    Object.entries(events).map(([key, value]:any) => (
                        <SwiperSlide key={key}>
                            <a className="event-card-link" href={`/events/${value.url}?start=${value.start}`}>
                                {/*  href={`/events/${this.props.model.url}`}>*/}
                                <article className="event-card-container"
                                         style={{
                                             minHeight: 300,
                                             backgroundImage: `url(${EVENT_IMG_URL}/${value.image_name})`
                                }}>
                                    <footer className="event-card-footer">
                                        <div className="event-card-meta">
                                           {/* <time dateTime={value.date.toISOString()}>
                                                {value?.eventType?.name} в
                                            </time>*/}
                                        </div>
                                        <h3 className="event-card-header">{value?.name}</h3>
                                    </footer>
                                </article>
                            </a>

                          {/*  {<div style={{
                                minHeight: "400px",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                // @ts-ignore
                                backgroundImage: `url(${EVENT_IMG_URL}/${value.image_name})`
                            }}>
                                <h2>{
                                    // @ts-ignore
                                    value?.name
                                }
                                </h2>
                            </div>}*/}
                        </SwiperSlide>
                    ))
                }


            </Swiper>
            <p className="text-center">
            <Link to="/events" className="btn  btn-lg btn-success mb-3">Расписание мероприятий</Link>
            </p>
            {/*
            <swiper-container
                //ref={swiperElRef}
                slides-per-view="1"
                height="100"
                navigation="true"
                pagination="true"
            >
                <swiper-slide>
                    <div>
                        <img src="/images/coach-hall-1.jpg" height="400px" alt="hall"/>
                        <div className="landingBoxWhite" style={{position: "absolute", bottom: 10, left: 10}}>
                            <h2>Пуфики</h2> тут есть приставки
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide><img src="/images/library-1.jpg" height="400px" alt="hall"/></swiper-slide>
                <swiper-slide><img src="/images/coach-hall-1.jpg" height="400px" alt="hall"/></swiper-slide>

            </swiper-container>*/}
        </div>
    );

}

export default AfishaPrice;
//module.exports = Main;
