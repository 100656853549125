import React from "react";
import FeedDayEvent from "./FeedDayEvent";
import formatDate from "../../components/utils/date/format-date";


interface Props {
    date: Date // React.PropTypes.instanceOf(Date).isRequired,
    events: any
}

class FeedDay extends React.Component<Props> {

    render() {
        const date = formatDate(this.props.date);
        const count = this.props.events.length;
        const even = count % 2 === 0;
        const events = this.props.events.map((event: any, i: number) => {
            const wide = !even && count - 1 === i;
            const className = wide ? 'col-sm-12' : 'col-sm-6';
            return (
                <div className={className} key={i}>
                    <FeedDayEvent model={event}/>
                </div>
            );
        });
        const rows = [];
        for (let i = 0; i < events.length; i++) {
            rows.push(
                <div className="row" key={i}>
                    {events[i]}
                    {events[++i]}
                </div>
            );
        }

        return (
            <section>
                <h2 className="feed-header">
                    <time className="feed-header-time">
                        {date + ' в антикафе Зеленая Дверь'}
                    </time>
                </h2>
                {rows}
            </section>
        );
    }
}

export default FeedDay;
