import React from "react";
import classNames from "classnames";


interface Props {
    active: number,
    onClick: (...args: any[]) => any;
}

const slides = [
    {},
    {
        title: 'Уютный внутренний двор',
        content: 'У нас есть стол для пинг-понга, шезлонги, гамаки и три дерева'
    },
    {
        title: 'В самом центре Москвы',
        content: 'Мы находимся в 3-х минутах ходьбы от трех станций: Чистые пруды, Тургеневская и Сретенский бульвар'
    },
    {
        title: 'Самое большое антикафе',
        content: 'У нас есть как уютные места, чтобы посидеть в небольшой компании, так и большие залы для проведения мероприятий от лекций до занятий йогой'
    },
    {}
];


class SlideNav extends React.Component<Props> {
    static defaultProps = {
        active: 0,
        onClick: () => {
        }
    }

    renderNav() {
        return slides.map((slide, i) => {
            const navItemClass = classNames('about-nav-item', {
                active: this.props.active === i
            });

            return <div className={navItemClass} onClick={this.props.onClick.bind(null, i)} key={i}/>

        });
    }

    render() {
        const activeSlide = slides[this.props.active];

        if (Object.keys(activeSlide).length !== 0) {
            return (
                <div className="about-slide-nav">
                    <h3>{activeSlide.title}</h3>
                    <p className="about-slide-nav-content">{activeSlide.content}</p>
                    {this.renderNav()}
                </div>
            );
        } else {
            return '';
        }

    }
}

export default SlideNav;
