import React from "react";
import Ribbon from "../../components/Ribbon";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faPlayCircle} from "@fortawesome/free-solid-svg-icons";

interface Props {
    active: boolean
    onClick: (...args: any[]) => any;
}


class ContactsAddress extends React.Component<Props> {

    render() {
        const className = classNames('contacts-address-container', {
            active: this.props.active
        });

        return (
            <div className={className}>
                <div className="contacts-address-block">
                    <div className="contacts-address-blazon">
                        <span className="contacts-address-anticafe"> Антикафе</span>
                    </div>
                    <Ribbon className="contacts-ribbon" title="Зеленая дверь" hideEdges/>
                    <div className="contacts-address-blazon">
                        <div className="contacts-house"> 19/4</div>
                        <div className="contacts-street">
                            <div className="contacts-street-name">
                                <span className="contacts-street-front">милютинский</span>
                            </div>
                            <br/>
                            <span className="contacts-lane">переулок</span>
                        </div>
                    </div>
                    <div className="contacts-triangle" style={{marginTop: -1}}/>
                    <div className="how-to-go">
                        <button className="contacts-play" onClick={this.props.onClick}>
                            {/* <span className="fa fa-play-circle"/>*/}
                            <FontAwesomeIcon icon={faPlayCircle}/>
                        </button>
                        <br/>
                        <strong>Как пройти: </strong>с Таней от метро чистые пруды
                    </div>
                </div>
                <div className="contacts-about-arrow hidden-xs">
                    <FontAwesomeIcon icon={faAngleDown}/>
                </div>
            </div>
        );
    }
}

export default ContactsAddress;
