import React from "react";
import ContactsAddress from "./ContactsAddress";
import ContactsVideo from "./ContactsVideo";
import Navigation from "../../layout/Navigation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faInstagram, faVk} from "@fortawesome/free-brands-svg-icons"
import {faHeart} from "@fortawesome/free-solid-svg-icons"
import {Helmet} from "react-helmet";

interface ContactProps {
    //showVideo: boolean
}

interface ContactState {
    showVideo: boolean
}

class Contacts extends React.Component<ContactProps, ContactState> {

    constructor(props: any) {
        super(props);
        this.state = {showVideo: false};
    }

    static defaultProps = {
        // showVideo: true,
    }

    onVideoToggle = () => {
        this.setState({showVideo: !this.state.showVideo});
    }

    render() {
        return (
            <div className="contacts">
                <Helmet>
                    <title>Контакты</title>
                </Helmet>

                <div className="contacts-navigation">
                    <Navigation/>
                </div>
                <ContactsVideo active={this.state.showVideo} onClick={this.onVideoToggle}/>
                <ContactsAddress active={!this.state.showVideo} onClick={this.onVideoToggle}/>
                <div className="contacts-police-box">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5 col-sm-7 hidden-xs">
                                <div className="contacts-doctor-who"></div>
                            </div>
                            <div className="col-sm-5 col-md-offset-1 col-md-5 ">
                                <ul className="contacts-list">
                                    <li>
                                        <span className="contacts-item">Телефон:</span>
                                        +7 495 532-87-58
                                    </li>
                                    <li>+7 968 463-74-91</li>
                                    <li>
                                        <span className="contacts-item">Вопросы:</span>
                                        <a className="contacts-link" href="mailto:info@zdver.com">info@zdver.com</a>
                                    </li>
                                    <li>
                                        <span className="contacts-item">Мероприятия:</span>
                                        <a className="contacts-link" href="mailto:events@zdver.com">event@zdver.com</a>
                                    </li>
                                    <li>
                                        <span className="contacts-item">Жалобы:</span>
                                        <a className="contacts-link"
                                           href="mailto:complaints@zdver.com">complaints@zdver.com</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contacts-social-container">
                    <a className="contacts-social facebook" href="https://www.facebook.com/zdver">
                        <FontAwesomeIcon icon={faFacebook} size="1x"/>
                    </a>
                    <a className="contacts-social vk" href="https://vk.com/zdver">
                        <FontAwesomeIcon icon={faVk} size="1x"/>
                    </a>
                    <a className="contacts-social instagram" href="https://instagram.com/door_green">
                        <FontAwesomeIcon icon={faInstagram} size="1x"/>
                    </a>
                </div>
            {/*    <div className="contacts-requisite">
                    <p>АО «Реставрационная фирма «Ваятель»</p>
                    <p>ИНН 7705018088, КПП 770801001, ОГРН 1027700350810</p>
                    <p>101000 г. Москва, Милютинский пер. дом 19/4 стр.1</p>
                </div>*/}
                <div className="contacts-partners-container">
                    <span>Любимые партнеры</span>
                    <br/>

                    <FontAwesomeIcon icon={faHeart} className="contacts-partners-heart"/>
                    {/* <span className="fa fa-heart contacts-partners-heart"/>*/}
                    <br/>
                    <a className="contacts-partner" href="https://kudago.com/" rel="nofollow">кудаго.ру</a>
                    <a className="contacts-partner" href="https://www.2do2go.ru/" rel="nofollow">2ду2го.ру</a>
                    <a className="contacts-partner" href="https://www.zovem.ru/" rel="nofollow">зовем.ру</a>
                    <a className="contacts-partner" href="https://www.lookatme.ru/" rel="nofollow">лукэтми.ру</a>
                    <a className="contacts-partner" href="https://theoryandpractice.ru/" rel="nofollow">тандп.ру</a>
                    <a className="contacts-partner" href="https://2anticafe.com/" rel="nofollow">туантикафе.ру</a>
                </div>
            </div>
        );
    }
}

export default Contacts;
//module.exports = Main;
