import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import '../../css/ext/swiper/index.scss';
//register();
const MainHallSlider = (props:any) => {


    //const swiperElRef = useRef(null);
    /*    useEffect(() => {
            swiperElRef?.current?.addEventListener('progress', (e) => {
                const [swiper, progress] = e.detail;
                console.log(progress);
                console.log(swiper);
            });

            swiperElRef?.current?.addEventListener('slidechange', (e) => {
                console.log('slide changed');
            });
        }, []);*/

    return (
        <div className="landingBoxWhite" style={{padding: 0}}>
            <p style={{padding: 10}}>

                <h2>Тематические залы</h2>
            </p>

            <Swiper
                //@ts-ignore
                pagination={true}
                navigation={true}
                //@ts-ignore
               // modules={[Pagination,Navigation]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div>
                        <img src="/images/coach-hall-1.jpg" height="400px" alt="hall"/>
                        <div className="landingBoxWhite" style={{position: "absolute", bottom: 10, left: 10}}>
                            <h2>Пуфики</h2>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div>
                        <img src="/images/library-1.jpg" height="400px" alt="hall"/>
                        <div className="landingBoxWhite" style={{position: "absolute", bottom: 10, left: 10}}>
                            <h2>Библиотека</h2>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div>
                        <img src="/images/halls/bz_1.jpg" height="400px" alt="hall"/>
                        <div className="landingBoxWhite" style={{position: "absolute", bottom: 10, left: 10}}>
                            <h2>Большой зал</h2>
                        </div>
                    </div>
                </SwiperSlide>


            </Swiper>
          {/*
            <swiper-container
                //ref={swiperElRef}
                slides-per-view="1"
                height="100"
                navigation="true"
                pagination="true"
            >
                <swiper-slide>
                    <div>
                        <img src="/images/coach-hall-1.jpg" height="400px" alt="hall"/>
                        <div className="landingBoxWhite" style={{position: "absolute", bottom: 10, left: 10}}>
                            <h2>Пуфики</h2> тут есть приставки
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide><img src="/images/library-1.jpg" height="400px" alt="hall"/></swiper-slide>
                <swiper-slide><img src="/images/coach-hall-1.jpg" height="400px" alt="hall"/></swiper-slide>

            </swiper-container>*/}
        </div>
    );
};

export default MainHallSlider;